<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">车辆管理</div>
				<div class="header-button">
					<el-button
						v-permission="'pc/car-center/car-manage/save'"
						type="success"
						@click="onCreate"
					>
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button>
					<el-button
						type="primary"
						v-permission="'pc/car-center/car-manage/annual-inspect'"
						@click="onInspection"
					>
						批量年检
					</el-button>
					<el-button
						@click="onRenewal"
						type="primary"
						v-permission="'pc/car-center/car-manage/renewal-insurance'"
					>
						批量续保
					</el-button>
					<el-button
						@click="onChangeSpecialist"
						type="primary"
						v-permission="'pc/car-center/car-manage/edit-mainten'"
					>
						修改维保员
					</el-button>
					<el-button @click="onImport" v-permission="'pc/car-center/car-manage/import'">
						Excel导入
					</el-button>
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/car-center/car-manage/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter">
					<template #licensePlateNums="{ filterParams }">
						<carNum :filterParams="filterParams" ref="carNumRef" />
					</template>
					<template #stockAreaId="{ filterParams }">
						<div class="stockAreaclsss">
							<span style="width: 60px" class="filteritemlabel">库存地</span>
							<el-select
								v-model="params.stockAreaId"
								@change="onChangeStockAreaId"
								placeholder="请选择库存地"
								filterable
								clearable
							>
								<el-option
									v-for="(item, index) in storageOptions"
									:key="index"
									:label="item.name"
									:value="item.id"
								/>
							</el-select>
						</div>
					</template>
				</yi-filter>
				<div class="button-list">
					<el-button
						v-for="(button, index) in statusList"
						:key="button.key"
						:class="selectedIndex === index ? 'selected-button' : ''"
						@click="onStatusChange(index, button)"
					>
						{{ button.title }}({{ count[button.key] }})
					</el-button>
				</div>
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					showSelection
					autoWidth
					:params="{ page }"
					@selection-change="onSelectionChange"
				>
					<template #status="{ value }">
						<span>{{ mapDict('status', value) }}</span>
					</template>
					<template #carModelName="{ value, row }">
						<span class="cursor" @click="onDetail(row)">{{ value || '--' }}</span>
					</template>
					<template #stockAreaName="{ row }">
						<span>{{ row.status === 7 ? '司机' : row.stockAreaName }}</span>
					</template>
					<template #operation="{ value, row }">
						<span
							v-permission="'pc/car-center/car-manage/edit'"
							class="cursor"
							@click="onEdit(row)"
						>
							编辑
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<management-form ref="formRef" :selectList="selectList" @on-refresh="fetchList" />
		<inspection-form ref="inspectionRef" @on-refresh="fetchList" />
		<edit-specialist ref="specialistRef" @on-refresh="fetchList" />
		<batch-renewal ref="renewalRef" :insurance-list="insuranceList" @on-refresh="fetchList" />
		<import-form ref="importRef" @on-refresh="fetchList" />
		<vehicle-detail ref="detailRef" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'VehicleManagement',
})
</script>

<script setup name="VehicleManagement">
import { inject, onMounted, ref, onActivated, getCurrentInstance } from 'vue'
import { setting, columns } from './config'
import { useState, useFetch, useDict } from '@/utils/hooks.js'
import { handleExport } from '@/utils/util'
import {
	vehicleList,
	insuranceCompanySelect,
	companySelect,
	channelSelect,
	storageSelect,
	maintenanceSelect,
	exportVehicle,
	getBrandSeriesSelect,
	carStatusStat,
} from '@/api/vehicle.js'
import { getStore } from '@/utils/store'
import carNum from '@/components/YiFilter/carNum'
import enums from '../enums'
import { useRoute } from 'vue-router'

import ManagementForm from './components/form'
import InspectionForm from './inspection'
import EditSpecialist from './editSpecialist'
import BatchRenewal from './renewal'
import ImportForm from './importCar'
import VehicleDetail from './detail'

const instance = getCurrentInstance()
const $message = inject('$message')
const userInfo = getStore({ name: 'userInfo' })

const formRef = ref(null)
const carNumRef = ref(null)
const inspectionRef = ref(null)
const specialistRef = ref(null)
const renewalRef = ref(null)
const detailRef = ref(null)
const importRef = ref(null)

const settingRef = ref(setting)

const [params, setParams] = useState({})
const [status, setStatus] = useState({ statusList: null })
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const [selected, setSelected] = useState([])
const [loading, setLoading] = useState(false)
const { isLoading, fetchData } = useFetch()
const [page, setPage] = useState({ current: 1, size: 20 })
const [selectedIndex, setIndex] = useState(0)
const [count, setCount] = useState({})
const [selectList, setSelect] = useState({})
const [insuranceList, setInsurance] = useState([])
const [storageOptions, setStorageOptions] = useState([])

const statusList = [
	{ title: '全部', key: 'all' },
	{ title: '待整备', key: 'awaitPrepared', status: [1] },
	{ title: '待维修', key: 'awaitRepair', status: [2] },
	{ title: '维修中', key: 'repair', status: [3] },
	{ title: '待出租', key: 'awaitLease', status: [4] },
	{ title: '出租中', key: 'lease', status: [7] },
	{ title: '待交车', key: 'awaitDelivery', status: [5] },
	{ title: '待退车', key: 'awaitReturn', status: [6] },
	{ title: '已报废', key: 'scrapped', status: [8] },
]

async function fetchList(refreshStatistics = true) {
	const payload = { page: page.value, ...params.value, ...status.value }
	if (payload.stockAreaId === 'driverMockId') {
		payload.stockAreaId = ''
	}
	const service = vehicleList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		if (refreshStatistics) fetchStat()
		setTable(data.records)
		setTotal(data.total)
	}
}

async function fetchStat() {
	const payload = { page: page.value, ...params.value }
	if (payload.stockAreaId === 'driverMockId') {
		payload.stockAreaId = ''
	}
	delete payload.statusList
	const { data, err } = await fetchData(carStatusStat, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setCount(data)
	}
}
function onEdit(row) {
	formRef.value.open(row)
}
function onChangeStockAreaId(val) {
	if (val === 'driverMockId') {
		// 设置状态
		const index = statusList.findIndex(item => item.status == 7)
		onStatusChange(index, statusList[index], false)
	}
}
function onDetail(val) {
	detailRef.value.open(val.id)
}
function onStatusChange(index, button, isClick = true) {
	setIndex(index)
	if (button.status) {
		setStatus({ statusList: button.status })
	} else {
		setStatus({ statusList: null })
	}
	// if (isClick && params.value.stockAreaId === 'driverMockId') {
	// 	params.value.stockAreaId = ''
	// }
	fetchList(false)
}
function mapData(list, labelValue = 'name', value = 'id') {
	return list.map(item => ({ label: item[labelValue], value: item[value] }))
}
function mapSelect(list, key = 'name', value = 'id') {
	return list.map(item => ({ name: item[key], code: item[value] }))
}
function mapDict(key, value) {
	const e = enums[key]
	if (!e) return '--'
	const target = e.find(item => item.code === value)
	return target ? target.name : '--'
}
async function fetchDict() {
	const dict = await useDict([
		'fuel_type',
		'annual_audit_status',
		'commercial_insurance_status',
		'compulsory_insurance_status',
		'car_info_query_file',
	])
	const annual = findItem('annualAuditStatusList')
	annual.options = dict['annual_audit_status']
	const commercial = findItem('commercialInsuranceStatusList')
	commercial.options = dict['commercial_insurance_status']
	const compulsory = findItem('compulsoryInsuranceStatusList')
	compulsory.options = dict['compulsory_insurance_status']
	const fuelTypes = findItem('fuelTypes')
	fuelTypes.options = dict['fuel_type']
	const carInfoQueryFile = findItem('carInfoQueryFile')
	carInfoQueryFile.options = dict['car_info_query_file']
}
async function fetchSelect() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [insuranceList, companyList, channelList, storageList, maintainList, seriesList] =
		await Promise.all([
			insuranceCompanySelect(),
			companySelect({ isPermission: true }),
			channelSelect({ isPermission: true }),
			storageSelect(20, { isPermission: true }),
			maintenanceSelect('Mainten', arr, { isAll: true }),
			getBrandSeriesSelect(2),
		])
	const list = {}
	if (insuranceList.code === 200) {
		list.insuranceList = mapData(insuranceList.data)
		setInsurance(mapData(insuranceList.data))
	}
	if (companyList.code === 200)
		list.companyList = mapData(companyList.data, 'companyId', 'companyName')
	if (channelList.code === 200) list.channelList = mapData(channelList.data)
	if (storageList.code === 200) list.storageList = mapData(storageList.data)
	if (seriesList.code === 200) list.seriesList = mapData(seriesList.data)
	setSelect(list)
	const channel = findItem('platformChannelId')
	const company = findItem('belongCompanyIds')
	const assetBelongCompany = findItem('assetBelongCompanyIds')
	const maintainer = findItem('maintainerId')
	const series = findItem('carSeriesIds')
	channel.options = mapSelect(channelList.data)
	company.options = mapSelect(companyList.data, 'companyName', 'companyId')
	assetBelongCompany.options = mapSelect(companyList.data, 'companyName', 'companyId')
	setStorageOptions([...storageList.data, { id: 'driverMockId', name: '司机' }])
	maintainer.options = mapSelect(maintainList.data, 'realName', 'id')
	series.options = mapSelect(seriesList.data)
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}

function onSelectionChange(list) {
	setSelected(list)
}
function onChangeFilter(event) {
	const date = {}
	;['createTime', 'clivtaDate', 'carInsuranceDate', 'annualAuditDate'].forEach(key => {
		const data = event[key]
		if (Array.isArray(data) && data.length) {
			const [start, end] = data
			date[`${key}Start`] = start
			date[`${key}End`] = end
		} else {
			delete params.value[`${key}Start`]
			delete params.value[`${key}End`]
		}
	})
	// 去除stockAreaId选项
	const { stockAreaId, ...restEvent } = event
	const payload = { ...params.value, ...date, ...restEvent }
	if (!event.statusList) delete payload.statusList
	;[
		'annualAuditStatusList',
		'commercialInsuranceStatusList',
		'compulsoryInsuranceStatusList',
	].forEach(key => {
		if (event[key]) {
			payload[key] = [event[key]]
		} else {
			delete payload[key]
		}
	})

	setParams(payload)
	fetchList()
}
function onCreate() {
	formRef.value.open()
}
// 批量年检
function onInspection() {
	if (!selected.value.length) return $message.error('请选择车辆')
	inspectionRef.value.open(selected.value)
}
function onRenewal() {
	if (!selected.value.length) return $message.error('请选择车辆')
	renewalRef.value.open(selected.value)
}
function onChangeSpecialist() {
	if (!selected.value.length) return $message.error('请选择车辆')
	const isSame = selected.value.every(
		item => item.belongCompanyId === selected.value[0].belongCompanyId,
	)
	if (!isSame) return $message.error('所属公司不一致，请重新选择！')
	specialistRef.value.open(selected.value)
}
function onImport() {
	importRef.value.open()
}
async function onExport() {
	setLoading(true)
	try {
		const res = await exportVehicle({ ...params.value, page: { ...page.value }, ...status.value })
		if (res) handleExport(res, '车辆管理')
	} catch (err) {
		$message.error(err.message)
	} finally {
		setLoading(false)
	}
}
onActivated(() => {
	const route = useRoute()
	const query = route.query
	if (Object.keys(query).length > 0) {
		Object.keys(params.value).forEach(key => delete params.value[key])
		if (carNumRef.value) carNumRef.value.onReset()
		params.value.belongCompanyIds = query?.companyIds?.split(',') || []
		params.value.keyword = query?.keyword || ''
		if (query.statusList) {
			if (query.statusList == 'all') {
				setIndex(0)
				setStatus({ statusList: null })
			} else {
				const status = query.statusList.split(',').map(item => Number(item))
				params.value.statusList = status
				setStatus({ statusList: status })
				if (status.length > 1) {
					const index = statusList.findIndex(item =>
						item.status ? item.status.every(code => status.includes(code)) : false,
					)
					setIndex(index)
				} else {
					const index = statusList.findIndex(item => item.status == status.toString())
					setIndex(index)
				}
			}
		}
		if (query.isInStock) {
			params.value.isInStock = JSON.parse(query.isInStock)
		} else {
			params.value.isInStock = null
		}
		instance.refs.filterRef && instance.refs.filterRef.manualSetParams(params.value)
	} else {
		fetchList()
	}
})
onMounted(() => {
	fetchSelect()
	fetchDict()
	fetchList()

	fetchStat()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.button-list {
		margin-top: 10px;
	}
	.selected-button {
		border: 1px solid #409eff;
		color: #409eff;
	}
	.cursor {
		cursor: pointer;
		color: #409eff;
	}
}
.filteritemlabel {
	font-family: PingFangSC-Regular;
	font-size: 12px;
	font-weight: 400;
	color: #a0a0a0;
	line-height: 14px;
	margin-right: 5px;
}
.stockAreaclsss {
	display: flex;
	align-items: center;
	margin-right: 10px;
	min-width: 180px;
}
</style>
